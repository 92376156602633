import React from "react";
import { useNavigate } from "react-router-dom";

export default function Success() {
    const navigate = useNavigate();
  return (
    <>
      <div className="mt-30 title text-center">Modification avec succès</div>
      <div className="text-center mt-20">
        <button
          className="btn"
          onClick={() => {
            navigate("/login");
          }}
        >
          Connexion
        </button>
      </div>
    </>
  );
}
