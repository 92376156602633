// axiosConfig.js

import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://localhost:8000/api',
  baseURL: 'https://adidybackendversion2.adidy.fr/api',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Configuration globale pour Axios pour inclure les en-têtes CORS
instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default instance;