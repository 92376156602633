import React, { useEffect, useRef, useState } from "react";
import logo from "./../images/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "./../axiosConfig";

function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [users, setUsers] = useState({});
  const [error2, setError2] = useState("");
  const [success, setSuccess] = useState("");
  const [stat, setStat] = useState();
  const passwordRef = useRef();
  const password_confirmationRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.post(`/validtoken`, { token });
        setUsers(response.data.user);
      } catch (error) {
        setStat(error.response.status);
        setError2(error.response.data.errors.password);
        navigate("/login");
      }
    };
    fetchData();
  }, [token, users.email, users.token, navigate]);

  useEffect(() => {
    if (success === 200) {
      navigate("/success");
    }
  }, [success, navigate]);

  useEffect(() => {
    if (stat === 401) {
      navigate("/login");
    }
  }, [stat, navigate]);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const utilisateurs = {
      password: passwordRef.current.value,
      password_confirmation: password_confirmationRef.current.value,
    };

    try {
      const response = await axiosInstance.post(
        `/token_change_password/${users.email}`,
        utilisateurs
      );
      setSuccess(response.data.status);
    } catch (error) {
      console.log(error);
      setError2(error.response.data.errors.password);
    }
  };

  const handleClick = () => {
    setError2("");
  };

  return (
    <>
      <div className="container-xxl">
        <div className="container-login">
          <div className="containt-login-logo">
            <img src={logo} alt="Logo de l'eglise" className="login-logo" />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="block-label">
              <span className="obligatoire">*</span>
              <label htmlFor="login" className="block-label">
                Login :
              </label>
            </div>
            <div className="block">
              <input
                type="email"
                className="form-control"
                value={users.email || ""}
                readOnly
              />
            </div>
            <div className="block-label">
              <span className="obligatoire">*</span>
              <label htmlFor="login" className="block-label">
                Mot de passe :
              </label>
              <span className="error">
                {error2 && "Mot de passe invalide !"}
              </span>
            </div>
            <div className="block">
              <input
                type="password"
                ref={passwordRef}
                className="form-control"
                id="password"
                placeholder="Entrez votre mot de passe..."
                onClick={handleClick}
                required
              />
            </div>
            <div className="block-label">
              <span className="obligatoire">*</span>
              <label htmlFor="login" className="block-label">
                Confirmez le mot de passe :
              </label>
              <span className="error">
                {error2 && "Mot de passe invalide !"}
              </span>
            </div>
            <div className="block">
              <input
                type="password"
                ref={password_confirmationRef}
                className="form-control"
                id="confirm_password"
                placeholder="Confirmez votre mot de passe..."
                onClick={handleClick}
                required
              />
            </div>
            <p className={!error2 ? "display-none" : "sous-error"}>
              Mot de passe : 6+ caractères, 1 majuscule !
            </p>
            <div className="block-button">
              <button type="submit" className="btn">
                Modifier
              </button>
              <button
                type="button"
                className="btn revoque ml-5"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Annuler
              </button>
            </div>
            <div className="block-link">
              <div className="forgot-password">
                {/* Liens supplémentaires si nécessaire */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
