import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

function ReportingAdminPage() {
  const [users, setMembre] = useState([]);
  const [loading, setLoading] = useState(true);
  const [action, setAction] = useState("");
  const [annee, setAnne] = useState(2024);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [searchFirstName, setSearchFirstName] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [usersId, setMembreId] = useState(null);
  const [usersect, setMembreSect] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [activeInput, setActiveInput] = useState("");
  const [dateselectedUser, setSelecteddateUser] = useState([]);

  //Admin role, récuperation de tous les utilisateurs
  const show_membre = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/show_membre_ayant_cotisation`)
      .then((response) => {
        setMembre(response.data.membres);
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          "Une erreur s'est produite lors du chargement des données:",
          error
        );
      });
  };
  useEffect(() => {
    show_membre();
  }, []);

  //Admin role, récuperation utilisateurs section
  const show_membre_section = async (usersId) => {
    if (usersId) {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/show_membre_section/${usersId}`
        )
        .then((response) => {
          if (response.data.membres_section.length === 0) {
            console.log("Secteur:", "Pas de section");
          } else {
            setMembreSect(response.data.membres_section);
          }
        })
        .catch((error) => {
          console.error(
            "Une erreur s'est produite lors du chargement des données:",
            error
          );
        });
    }
  };

  useEffect(() => {
    show_membre_section();
  }, []);

  if (loading) {
    return (
      <>
        <div className="loading">
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        </div>
      </>
    );
  }

  // Gestionnaire d'événements pour la modification de le membre sélectionné
  function handleInputChange(event) {
    const userId = event.target.value;

    setMembreId(userId);

    setAction("checked");

    setAnne(2024);

    setMembreSect([]);

    setActiveInput("active");

    show_membre_section(userId);

    // Recherche de l'membre par son ID
    const selectedUser = users.find((user) => {
      return user.id === parseInt(userId);
    });

    // Vérifiez si membre sélectionné exist dans l'users
    if (selectedUser) {
      setSelectedUser(selectedUser);

      // Formatage de la date selon le format DD-MM-YYYY
      let dateFormatee = format(new Date(selectedUser.dat_nais), "dd-MM-yyyy");
      setSelecteddateUser(dateFormatee);
    } else {
      console.log("Aucun membre trouvé pour la valeur spécifiée :", userId);
    }
  }

  //fuction etat membre
  function EtatMembre() {
    const [cotisation, setCotisation] = useState([]);
    const [clickedA, setclickedAdidy] = useState(false);
    const [clickedD, setclickedDon] = useState(false);
    const [clickedS, setclickedSous] = useState(false);
    const [clickedESP, setclickedESP] = useState(false);
    const [clickedVIR, setclickedVIR] = useState(false);
    const [clickedCHE, setclickedCHE] = useState(false);
    const [clickedCB, setclickedCB] = useState(false);
    const [searchAnnee, setSearchAnnee] = useState(
      new Date().getFullYear().toString()
    );
    const [sercheTyp_pmt, setsearchTyp_pmt] = useState("");
    const [sercheMode_pmt, setsearchMode_pmt] = useState("");

    const show_cotisation = async () => {
      try {
        if (usersId) {
          // S'assurer que l'utilisateur est défini
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/show_cotisation_users/${usersId}`
          );
          setCotisation(response.data.cotisation);
        } else {
          console.log("L'utilisateur n'est pas encore défini.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (cotisation) {
        show_cotisation();
      }
    }, [cotisation]);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const cotisationPerPage = 8;

    const handleClick = (state, setState) => {
      // Inverser la valeur de l'état lors du clic
      setState((prevState) => !prevState);
    };

    const handleSearchCotisation = (e) => {
      e.preventDefault();
      setSearchPerformed(true); // Indique qu'une recherche a été effectuée
      setCurrentPage(1); // Réinitialiser la pagination à la première page lors de la recherche
    };

    // Fonction pour changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Utiliser la liste filtrée si une recherche a été effectuée, sinon utiliser la liste complète
    const filteredCotisation = searchPerformed
      ? cotisation.filter((user) => {
          const anneeMatch = new Date(user.dat_pmt)
            .getFullYear()
            .toString()
            .toLowerCase()
            .includes(searchAnnee.toLowerCase());
          const typ_pmt = user.typ_mt
            .toString()
            .toLowerCase()
            .includes(sercheTyp_pmt.toLowerCase());
          const mode_pmt = user.mod_pmt
            .toString()
            .toLowerCase()
            .includes(sercheMode_pmt.toLowerCase());
          return anneeMatch && typ_pmt && mode_pmt;
        })
      : cotisation;

    const indexOfLastUser = currentPage * cotisationPerPage;
    const indexOfFirstUser = indexOfLastUser - cotisationPerPage;
    const currentCotisation = cotisation
      ? filteredCotisation.slice(indexOfFirstUser, indexOfLastUser)
      : [];

    return (
      <>
        <div className="block-liste block-liste-membre mt-30">
          <div className="liste-title">
            <p className="uppercase">{selectedUser.prenom}</p>
          </div>
          <div className="liste-containt liste-membre">
            <div className="table-membre">
              <div className="data">
                <p className="subtitle">Identifiants :</p>
                <div className="data-containte">
                  <div className="dataBox">
                    <div className="champ">
                      <label>Matricule :</label>
                      <input
                        type="email"
                        className="input-form input-data"
                        readOnly
                        value={selectedUser.id}
                      />
                    </div>
                    <div className="champ">
                      <label>Nom : </label>
                      <input
                        type="text"
                        className="input-form input-data"
                        id="name"
                        readOnly
                        value={selectedUser.nom}
                      />
                    </div>
                    <div className="champ">
                      <label>Date de naissance :</label>
                      <input
                        type="text"
                        readOnly
                        className="input-form input-data w-small"
                        id="naissance"
                        value={
                          selectedUser.dat_nais === null
                            ? "Non défini"
                            : dateselectedUser
                        }
                      />
                    </div>
                    <div className="champ">
                      <label>Genre :</label>
                      <input
                        type="text"
                        className="input-form input-data"
                        readOnly
                        value={
                          selectedUser.genre === "M" ? "Masculin" : "Feminin"
                        }
                      />
                    </div>
                    <div className="champ">
                      <label>Prénom(s) :</label>
                      <input
                        type="text"
                        className="input-form input-data"
                        readOnly
                        value={selectedUser.prenom}
                      />
                    </div>
                    <div className="champ"></div>
                  </div>
                </div>
              </div>
              <div className="data">
                <p className="subtitle">Adresse</p>
                <div className="data-containte">
                  <div className="dataBox">
                    <div className="champ">
                      <label>E-mail :</label>
                      <input
                        type="email"
                        className="input-form input-data"
                        readOnly
                        value={selectedUser.e_mail}
                      />
                    </div>
                    <div className="champ">
                      <label>Voie adresse :</label>
                      <input
                        type="text"
                        className="input-form input-data w-small"
                        id="adress"
                        readOnly
                        value={selectedUser.adr_voie}
                      />
                    </div>
                    <div className="champ">
                      <label>Code postal :</label>
                      <input
                        type="text"
                        className="input-form input-data w-small"
                        readOnly
                        value={selectedUser.adr_cp}
                      />
                    </div>
                    <div className="champ">
                      <label>Ville :</label>
                      <input
                        type="text"
                        className="input-form input-data"
                        readOnly
                        value={selectedUser.adr_ville}
                      />
                    </div>
                    <div className="champ">
                      <label>Indice Resp :</label>
                      <input
                        type="text"
                        className="input-form input-data w-small"
                        readOnly
                        value={
                          selectedUser.ind_resp === "1" ||
                          selectedUser.ind_resp === "O" ||
                          selectedUser.ind_resp === 1
                            ? "Responsable"
                            : "Non responsable"
                        }
                      />
                    </div>
                    <div className="champ"></div>
                  </div>
                </div>
              </div>
              <div className="data section">
                <p className="subtitle">
                  Membre section : {usersId},{" "}
                  <span className="capitalize">{selectedUser.prenom}</span>
                </p>
                <div className="data-containte data-containte-section">
                <div className="dataBox-sm responsive-600">
                    <div className="champ">
                      <p>Sampana vokovoko Manga : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SVM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SVM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>S Lehilahy Kristianina : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SLK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SLK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>S vehivavy Kristianina : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SVK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SVK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Sekoly Alahady : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SA")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SA")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Antoko Mpihira Fiderana : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("AMF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("AMF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>S Tanora Kristianina : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("STK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("STK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>S Fampianarana T.A : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SFT")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SFT")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Sampana zava-maneno : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SZM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SZM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Sampana Fifohazana : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Sampana Tily: </p>
                      <div>
                        <button
                          className={
                            usersect.includes("ST")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("ST")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ"></div>
                    <div className="champ"></div>
                  </div>
                  <div className="dataBox-sm-600 responsive-on-600">
                    <div className="champ">
                      <p>SVM : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SVM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SVM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SLK : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SLK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SLK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SVK : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SVK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SVK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SA : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SA")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SA")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>AMF : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("AMF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("AMF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>STK: </p>
                      <div>
                        <button
                          className={
                            usersect.includes("STK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("STK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SFT : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SFT")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SFT")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SZM : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SZM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SZM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SF : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>ST : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("ST")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("ST")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bar mt-30"></div>
        <div className="block-liste block-liste-cherche block-liste-cherche-reporting mt-30">
          <div className="liste-title">
            <p className="size-384">
              RECHERCHE COTISATIONS{" "}
              <span className="uppercase">{selectedUser.prenom}</span>
            </p>
          </div>
          <form onSubmit={handleSearchCotisation}>
            <div className="liste-recherche">
              <div className="champ-cherche-xsm">
                <label className="label-form">Type cotisation : </label>
                <div className="btn-filter">
                  <button
                    type=""
                    className={
                      sercheTyp_pmt === "A"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedA, setclickedAdidy);
                      setclickedDon("");
                      setclickedSous("");
                      setsearchTyp_pmt("A");
                    }}
                  >
                    A
                  </button>
                  <button
                    type=""
                    className={
                      sercheTyp_pmt === "D"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedD, setclickedDon);
                      setclickedAdidy("");
                      setclickedSous("");
                      setsearchTyp_pmt("D");
                    }}
                  >
                    D
                  </button>
                  <button
                    type=""
                    className={
                      sercheTyp_pmt === "S"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedS, setclickedSous);
                      setclickedAdidy("");
                      setclickedDon("");
                      setsearchTyp_pmt("S");
                    }}
                  >
                    S
                  </button>
                </div>
              </div>
              <div className="champ-cherche-xsm">
                <label className="label-form">Mode paiement : </label>
                <div className="btn-filter">
                  <button
                    type=""
                    className={
                      sercheMode_pmt === "ESP"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedESP, setclickedESP);
                      setclickedCHE("");
                      setclickedVIR("");
                      setclickedCB("");
                      setsearchMode_pmt("ESP");
                    }}
                  >
                    ESP
                  </button>
                  <button
                    type=""
                    className={
                      sercheMode_pmt === "CHE"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedCHE, setclickedCHE);
                      setclickedESP("");
                      setclickedVIR("");
                      setclickedCB("");
                      setsearchMode_pmt("CHE");
                    }}
                  >
                    CHE
                  </button>
                  <button
                    type=""
                    className={
                      sercheMode_pmt === "VIR"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedVIR, setclickedVIR);
                      setclickedCHE("");
                      setclickedESP("");
                      setclickedCB("");
                      setsearchMode_pmt("VIR");
                    }}
                  >
                    VIR
                  </button>
                  <button
                    type=""
                    className={
                      sercheMode_pmt === "CB"
                        ? "radio-binary radio-binary-lg cheked"
                        : "radio-binary radio-binary-lg"
                    }
                    onClick={() => {
                      handleClick(clickedCB, setclickedCB);
                      setclickedCHE("");
                      setclickedVIR("");
                      setclickedESP("");
                      setsearchMode_pmt("CB");
                    }}
                  >
                    CB
                  </button>
                </div>
              </div>
              <div className="champ-cherche-xsm">
                <label className="label-form">Année : </label>
                <input
                  type="number"
                  name="yearInput"
                  className="input-form mt-10"
                  defaultValue={new Date().getFullYear()}
                  onChange={(e) => setSearchAnnee(e.target.value)}
                  placeholder="2024"
                />
              </div>
              <div className="champ-cherche-xsm">
                <button type="submit" className="btn btn-sm ml-5">
                  Rechercher
                </button>
                <button
                  type="button"
                  className="btn btn-sm ml-5"
                  onClick={() => {
                    setclickedAdidy("");
                    setclickedDon("");
                    setclickedSous("");
                    setclickedESP("");
                    setclickedVIR("");
                    setclickedCHE("");
                    setclickedCB("");
                    setSearchAnnee(new Date().getFullYear().toString());
                    setSearchPerformed(false);
                    setSelecteddateUser("");
                    setsearchMode_pmt("");
                    setsearchTyp_pmt("");
                    // Réinitialiser la sélection de l'utilisateur
                    const radioButtons = document.querySelectorAll(
                      'input[type="radio"][name="selectedUser"]'
                    );
                    radioButtons.forEach((button) => {
                      button.checked = false;
                    });
                  }}
                >
                  Réinitialiser
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="block-liste mt-30">
          <div className="liste-title">
            <p className="size-384">
              LISTE DES COTISATIONS{" "}
              <span className="uppercase">{selectedUser.prenom}</span>
            </p>
          </div>
          <div className="liste-containt">
            <div className="table">
              <div className="theader">
                <div className="check">
                  <div className="box"></div>
                </div>
                <div className="w-100">Matricule</div>
                <div className="w-200">Type Cot.</div>
                <div className="w-200 responsive-600">Mode Pmt</div>
                <div className="w-200">Montant</div>
                <div className="w-200 responsive-600">Date Pmt</div>
                <div className="w-300  responsive-998">Notes</div>
              </div>
              <div className="tbody">
                {currentCotisation.map((user) => (
                  <div key={user.id} className="liste-value">
                    <div>
                      <input
                        className="check check-box"
                        type="radio"
                        name="selectedUser"
                      />
                    </div>
                    <div className="w-100">{user.id_mbr}</div>
                    <div className="w-200">
                      {user.typ_mt === "A"
                        ? "Adidy"
                        : user.typ_mt === "S"
                        ? "Souscription"
                        : user.typ_mt === "D"
                        ? "Don"
                        : ""}
                    </div>
                    <div className="w-200 responsive-600">
                      {user.mod_pmt === "ESP"
                        ? "Espèce"
                        : user.mod_pmt === "CHE"
                        ? "Chèque"
                        : user.mod_pmt === "VIR"
                        ? "Virement"
                        : user.mod_pmt === "CB"
                        ? "Carte bleue"
                        : user.mod_pmt === "CHE"
                        ? "Espèces"
                        : ""}
                    </div>
                    <div className="w-200">{user.mt}</div>
                    <div className="w-200 responsive-600">
                      {format(new Date(user.dat_pmt), "dd-MM-yyyy")}
                    </div>
                    <div className="w-300 responsive-998">{user.notes}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="button-modif button-modif-xxl">
          <div className="button-modif-action"></div>
          <div className="pagination">
            <div className="right-page">
              {Array.from(
                {
                  length: Math.ceil(
                    filteredCotisation.length / cotisationPerPage
                  ),
                },
                (_, index) => {
                  const startPage = Math.max(currentPage - 2, 0);
                  const endPage = Math.min(
                    startPage + 3,
                    Math.ceil(filteredCotisation.length / cotisationPerPage)
                  );

                  if (index >= startPage && index < endPage) {
                    return (
                      <button
                        key={index}
                        onClick={() => paginate(index + 1)} // Appeler la fonction paginate avec le numéro de page correspondant
                        className={
                          currentPage === index + 1
                            ? "pageNum active"
                            : "pageNum"
                        }
                      >
                        {index + 1}
                      </button>
                    );
                  } else if (index === startPage - 1 && currentPage > 3) {
                    return <span key="ellipsis-start">{"<<"}</span>;
                  } else if (
                    index === endPage &&
                    currentPage <
                      Math.ceil(filteredCotisation.length / cotisationPerPage) -
                        2
                  ) {
                    return <span key="ellipsis-end">{">>"}</span>;
                  }
                  return null;
                }
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  //fuction etat membre
  function EtatImpotDons() {
    const page1Ref = useRef();
    const page2Ref = useRef();
    const [num, setNum] = useState("");
    const [stat, setStat] = useState("");
    const [montant, setMontant] = useState("");
    const [montantLettre, setMontantLettre] = useState("");

    const show_mt_don = async () => {
      if (usersId) {
        await axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/show_mt_don/${usersId}/${annee}`
          )
          .then((response) => {
            if (response.data.status === 200) {
              setMontant(response.data.montantTotal);
              setNum(response.data.num);
              setMontantLettre(response.data.Lettres);
              setStat("recucerfa");
            } else {
              setStat("error");
              console.log(response);
            }
          })
          .catch((error) => {
            console.error(error);
            if (error.response.data.status === 401) {
              setStat("nocotisation");
            } else {
              setStat("error");
              console.log(error);
            }
          });
      } else {
        console.log("UsersId non définit !");
      }
    };
    useEffect(() => {
      show_mt_don();
    }, []);

    const padNumber = (numero, length) => {
      return numero.toString().padStart(length, "0");
    };

    const exportToPDF = async () => {
      const doc = new jsPDF("p", "mm", "a4");

      const addPageToPDF = async (element, doc) => {
        const canvas = await html2canvas(element, {scale: 2});
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        doc.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      };

      await addPageToPDF(page1Ref.current, doc);
      doc.addPage();
      await addPageToPDF(page2Ref.current, doc);

      doc.save(`${selectedUser.prenom}_cerfa_reçu.pdf`);
    };

    function RecuCERFA() {
      return (
        <>
          <div className="text-center title">
            Reçu Cerfa de <span className="capitalize">{selectedUser.prenom}</span> pour{" "}
            {annee === "2024" || annee === 2024
              ? "cette année"
              : `l'année ${annee}`}{" "}
            :
          </div>
          <div className="bar mt-30"></div>
          <div className="btn-exporter">
            <button className="btn-param ml-5" onClick={() => exportToPDF()}>
              Exporter en PDF
            </button>
            <button
              className="btn-param revoque ml-5"
              onClick={() => handleActionChange("etatannee")}
            >
              Changer l'année
            </button>
          </div>
          <div className="bordered  mt-30">
            <div className="A4pdf1" ref={page1Ref}>
              <div className="header_recu mt-30">
                <div className="number_recu">
                  <div className="cerfa"></div>
                  <h2 className="mt-10">N° : 11580*03</h2>
                </div>
                <div className="title_recu">
                  <h1 className="text-center">
                    Reçu au titre des dons <br /> à certains organismes
                    d'intérêt général
                  </h1>
                  <p className="text-center mt-10">
                    Article 200, 238 bis et 885-0 V bis A du code général des
                    impôts (CGI)
                  </p>
                </div>
                <div className="number_recu mt-30 number-recu-p">
                  <p className="">
                    Numéro d'ordre du reçu <br /> {annee}-{padNumber(num, 4)}
                  </p>
                </div>
              </div>
              <div className="body_recu mt-30">
                <h2 className="underline">Bénéficiaire des versements :</h2>
                <p className="mt-20">
                  Nom ou dénomination :{" "}
                  <b>EGLISE PROTESTANTE MALGACHE EN FRANCE - FPMA VINCENNES</b>
                </p>
                <p className="mt-10">
                  Adresse : <b>N° 12, Rue Monmory</b>
                </p>
                <p className="mt-10">
                  Code postal : <b>94300</b>, Commune : <b>VINCENNES</b>
                </p>
                <h3 className="mt-20">
                  <span className="underline">Objet :</span>{" "}
                  <span className="objet">Assurer la célébration du culte</span>
                </h3>
                {/* <div className="bar mt-30"></div> */}
                <h3 className="underline mt-20">
                  Cochez la case concernée (1) :
                </h3>
                <p className="mt-20">
                  <input type="text" className="case" readOnly />
                  Association ou fondation reconnue d'utilité public par décret
                  en date du ...../..../...... publié au journal officiel du
                  ..../..../....... ou association située dans le département
                  de la Moselle, du Bas-Rhin ou du Haut-Rhin dans la mission a
                  été reconnue d'utilité public par arrêté préfectoral en date
                  du ..../..../...... .
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Fondation
                  universitaire ou fondation partenariale mentionnées
                  respectivement aux arcticles L. 719-12 et L. 719-13 du code de
                  l'éducation.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Fondation
                  d'entreprise
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Œuvre ou
                  organisme d'intérêt général.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Musée de
                  France.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Etablissement
                  d'enseignement supérieur ou d'enseignement artistique public
                  ou privé, d'intérêt général, à but non lucratif.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Organisme
                  ayant pour objet exclusif de participer financièrement à la
                  création d'entreprises.
                </p>
                <p className="mt-10">
                  <input type="checkbox" className="case" readOnly checked />{" "}
                  Association cultuelle ou de bienfaisance et établissement
                  public des cultes reconnus d'Alsace-Moselle.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Organisme
                  ayant pour activité principale l'organisation de festivals.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Association
                  fournissant gratuitement une aide alimentaire ou des soins
                  médicaux à des personnes en difficulté ou favorisant leur
                  logement
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Fondation du
                  patrimoine ou fondation ou association qui affecte
                  irrévocablement les Dons à la fondation du patrimoine, en vue
                  de subventionner les travaux prévus par les convention
                  conclues entre la fondation du patrimoine et les propriétaires
                  des immeubles (Article L. 143-2-1 du code du patrimoine)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Etablissement
                  de recherche public ou privé, d'intérêt général, à but non
                  lucratif.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Entreprises
                  d'insertion ou entreprise de travail temporaire d'insertion
                  (Articles L. 5132-5 et L. 5132-6 du code de travail.)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Associations
                  intermédiaires (Article L. 5132-7 du code du travail
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Ateliers et
                  chantiers d'insertion (Article L. 5132-15 du code de travail)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Entreprises
                  adaptées (Article L. 5213-13 du code du travail.)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Agence
                  nationnal de la recherche (ANR)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Société ou
                  organisme agréé de recherche scientifique ou technique (2)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Autre
                  organisme :
                  ....................................................................
                </p>
                <div className="bar mt-20 bar-mini-text"></div>
                <div className="mini-text">
                  (1) ou n'indiquez que les renseignements concernant
                  l'organisme
                </div>
                <div className="mini-text">
                  (2) dons effectués par les entreprises
                </div>
              </div>
            </div>
          </div>
          {/* <div className="bar mt-30 responsive-1200"></div> */}
          <div className="bordered  mt-30">
            <div className="A4pdf1" ref={page2Ref}>
              <h2 className="underline">Donateur :</h2>
              <p className="mt-30 donateur-p">
                Nom :{" "}
                <span className="uppercase mt-10 donateur">
                  {selectedUser.nom}
                </span>
              </p>
              <p className="mt-10">
                Prénoms :{" "}
                <span className="capitalize mt-10 donateur">
                  {selectedUser.prenom}
                </span>
              </p>
              <p className="mt-10">
                Adresse :{" "}
                <span className="capitalize mt-10 donateur">
                  {selectedUser.adr_voie}
                </span>
              </p>
              <p className="mt-10">
                Code postal :{" "}
                <span className="capitalize mt-10 donateur mr-80">
                  {selectedUser.adr_cp},
                </span>
                Commune :{" "}
                <span className="mt-10 donateur">{selectedUser.adr_ville}</span>
              </p>
              {/* <div className="bar mt-30"></div> */}
              <p className="mt-10">
                Le bénéficiaire reconnait avoir reçu au titre des dons et
                versements ouvrant droit à réduction d'impôt, la somme de :
              </p>
              <div className="mt-30">
                <div className="input-impot">
                  {montant}
                  <b className="ml-20">€</b>
                </div>
              </div>
              <p className="mt-20">
                Somme en toutes lettres :{" "}
                <span className="ml-5">
                  <b className="capitalize">{montantLettre} Euros</b>
                </span>
              </p>
              <p className="mt-10">
                Date du versement ou du don : 31/12/{annee}
              </p>
              <p className="mt-10">
                Le bénéficiaire certifie sur l'honneur que les dons et
                versements qu'il reçoit ouvrent droit à la reduction d'impôt prévue à
                l'article (3) :{" "}
              </p>
              <p className="mt-20 paren-align-case">
                <span className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  200 du CGI
                </span>
                <span className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  238 Bis du CGI
                </span>
                <span className="align-case">
                  <input type="text" className="case" readOnly />
                  885-0 Bis A du CGI
                </span>
                <span className="align-case"></span>
              </p>
              {/* <div className="bar mt-30"></div> */}
              <p className="mt-20 underline">
                <b>Forme du don :</b>
              </p>
              <p className="mt-20 paren-align-case">
                <span className="align-case">
                  <input type="text" className="case" readOnly />
                  Acte authentique
                </span>
                <span className="align-case">
                  <input type="text" className="case" readOnly />
                  Acte sous seing privé
                </span>
                <span className="align-case">
                  <input type="text" className="case" readOnly />
                  Déclaration de don manuel
                </span>
                <span className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  Autres
                </span>
              </p>
              {/* <div className="bar mt-30"></div> */}
              <p className="mt-20 underline">
                <b>Nature du don : </b>
              </p>
              <p className="mt-20 paren-align-case">
                <div className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  Numéraire
                </div>
                <div className="align-case">
                  <input type="text" className="case" readOnly />
                  Titres des sociétés cotés
                </div>
                <div className="align-case">
                  <input type="text" className="case" readOnly />
                  Autres (4)
                </div>
                <div className="align-case"></div>
              </p>
              {/* <div className="bar mt-30"></div> */}
              <p className="mt-20 underline">
                <b>En cas de don numéraire, mode de versement du don : </b>
              </p>
              <p className="mt-20 paren-align-case">
                <div className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  Remise d'espèces
                </div>
                <div className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  Chèque
                </div>
                <div className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  Virement, prélèvement
                </div>
                <div className="align-case">Carte bancaire</div>
              </p>
              {/* <div className="bar mt-30"></div> */}
              <div className="mini-text">
                <span className="mr-20">(3)</span> L'organisme bénéficiaire peut
                cocher une ou plusieurs cases. <br /> L'organisme bénéficiaire
                peut, en application de l'article L. 80 C du livre des
                procédures fiscales, demander à l'administration s'il relève de
                l'une de cathégories d'organismes mentionnées aux articles 200
                et 238 bis du code général des impôts. <br /> Il est rappelé que
                la délivrance irrégulières de reçus fiscaux par l'organisme
                bénéficiaire est susceptible de donner lieu, en application des
                dispositions de l'article 1740 A du code général des impôts, à
                une amende fiscale égale à 25% des sommes indûment mentionnées
                sur ces documents.
              </div>
              <div className="mini-text mt-20 mini-text-4">
                <span className="mr-20">(4)</span> notament : abandon de revenus
                ou de produits ; frais engagés par les bénévoles, dont ils
                renoncent expréssement au remboursement.
              </div>
              <div className="signature">
                <div className="bloc-signature text-center">
                  <h3 className="">Date et signature</h3>
                  <p className="mt-10">31/12/{annee}</p>
                  <div className="img-signature mt-10"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-exporter mt-30">
            <button className="btn-param ml-5" onClick={() => exportToPDF()}>
              Exporter en PDF
            </button>
            <button
              className="btn-param revoque ml-5"
              onClick={() => handleActionChange("etatannee")}
            >
              Changer l'année
            </button>
          </div>
        </>
      );
    }

    function NoCotisation() {
      return (
        <>
          <div className="text-center title">
            Aucune cotisation pour{" "}
            {annee === "2024" || annee === 2024
              ? "cette année"
              : `l'année ${annee}`}{" "}
            !
          </div>
          <div className="btn-exporter">
            <button
              className="btn-param revoque ml-5"
              onClick={() => handleActionChange("etatannee")}
            >
              Changer l'année
            </button>
          </div>
        </>
      );
    }

    function Error() {
      return (
        <div className="text-center subtitle">
          Erreur s'est produit lors de la connexion à la base de donnée
        </div>
      );
    }

    //gestion pages avec button
    const renderFormAction = () => {
      switch (stat) {
        case "recucerfa":
          return <RecuCERFA />;
        case "nocotisation":
          return <NoCotisation />;
        case "error":
          return <Error />;
        default:
          return "";
      }
    };

    return renderFormAction();
  }

  function EtatAnnee() {
    const [searchAnnee, setSearchAnnee] = useState(2024);

    const handleSubmitAnne = async (ev) => {
      ev.preventDefault();
      setAnne(searchAnnee);
      console.log(annee);
      setAction("etat_impot");
    };

    return (
      <>
        <form onSubmit={handleSubmitAnne}>
          <p className="label-form mr-20">
            Sélectionnez l'année pour le reçu fiscal de{" "}
            <b className="capitalize">{selectedUser.prenom}</b>
          </p>
          <input
            type="number"
            className="input-form input-data mt-10"
            min="1900"
            max="2300"
            value={searchAnnee}
            onChange={(e) => setSearchAnnee(e.target.value)}
            required
          />
          <button type="submit" className="btn-param ml-5">
            Valider
          </button>
        </form>
      </>
    );
  }

  //pagination
  const usersPerPage = 8;

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  // Utilisez la liste filtrée si une recherche a été effectuée, sinon utilisez la liste complète
  const filteredUsers = searchPerformed
    ? users.filter((user) => {
        const nameMatch = user.nom
          .toLowerCase()
          .includes(searchName.toLowerCase());
        const firstNameMatch = user.prenom
          .toLowerCase()
          .includes(searchFirstName.toLowerCase());
        return nameMatch && firstNameMatch;
      })
    : users;

  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchPerformed(true); // Indique qu'une recherche a été effectuée
    setCurrentPage(1); // Réinitialisez la pagination à la première page lors de la recherche
  };
  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleActionChange = (newAction) => {
    setAction(newAction);
  };

  //gestion pages avec button
  const renderForm = () => {
    switch (action) {
      case "etatmembre":
        return <EtatMembre />;
      case "etatannee":
        return <EtatAnnee />;
      case "etat_impot":
        return <EtatImpotDons />;
      default:
        return "";
    }
  };

  return (
    <>
      <div className="container">
        <div className="container-lg">
          <div className="block-liste block-liste-cherche mt-30">
            <div className="liste-title">
              <p>RECHERCHE</p>
            </div>
            <form onSubmit={handleSearch}>
              <div className="liste-recherche">
                <div className="champ-cherche">
                  <div>
                    <label for="" className="label-form">
                      Nom :{" "}
                    </label>
                    <input
                      type="text"
                      id="id"
                      name="name"
                      className="input-form mt-10"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      placeholder="Entrez le nom à rechercher..."
                    />
                  </div>
                </div>
                <div className="champ-cherche">
                  <div>
                    <label for="" className="label-form">
                      Prénom :{" "}
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="input-form mt-10"
                      value={searchFirstName}
                      onChange={(e) => setSearchFirstName(e.target.value)}
                      placeholder="Entrez le prénom à rechercher..."
                    />
                  </div>
                </div>
                <div className="champ-cherche">
                  <div>
                    <label for="" className="label-form"></label>
                    <div className="">
                      <button type="submit" className="btn ml-5">
                        Rechercher
                      </button>
                      <button
                        type="button"
                        className="btn ml-5"
                        onClick={() => {
                          setSearchName("");
                          setSearchFirstName("");
                          setSearchPerformed(false);
                          setAction("");
                          setActiveInput("");
                          setAnne(2024);
                          // Réinitialiser la sélection de l'utilisateur
                          const radioButtons = document.querySelectorAll(
                            'input[type="radio"][name="selectedUser"]'
                          );
                          radioButtons.forEach((button) => {
                            button.checked = false;
                          });
                        }}
                      >
                        Réinitialiser
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="bar mt-30"></div>
          <div className="block-liste">
            <div className="liste-title">
              <p>LISTE DES MEMBRES</p>
            </div>
            <div className="liste-containt">
              <div className="table">
                <div className="theader">
                  <div className="check">
                    <div className="box"></div>
                  </div>
                  <div className="w-100">N° :</div>
                  <div className="w-200">Nom</div>
                  <div className="w-200 responsive-998">Prénom</div>
                  <div className="w-200 responsive-600">Téléphone</div>
                  <div className="w-200 responsive-480">Adresse Voie</div>
                  <div className="w-300 responsive-768">Ville</div>
                </div>
                <div className="tbody">
                  {currentUsers.map((user, index) => (
                    <div key={user.id} className="liste-value">
                      <div>
                        <input
                          className="check check-box"
                          type="radio"
                          name="selectedUser"
                          value={user.id}
                          onChange={(event) => handleInputChange(event)}
                        />
                      </div>
                      <div className="w-100">{index + 1}</div>
                      <div className="w-200">{user.nom}</div>
                      <div className="w-200 responsive-998">{user.prenom}</div>
                      <div className="w-200 responsive-600">{user.tel}</div>
                      <div className="w-200 responsive-480">{user.adr_voie}</div>
                      <div className="w-300 responsive-768">{user.adr_ville}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="button-modif button-modif-xxl">
            <div className="button-modif-action">
              <button
                className={!activeInput ? "display-none" : "btn-param ml-5"}
                onClick={() => handleActionChange("etat_impot")}
              >
                Etat impôt Dons
              </button>
              <button
                className={!activeInput ? "display-none" : "btn-param ml-5"}
                onClick={() => handleActionChange("etatmembre")}
              >
                Etat Membre
              </button>
            </div>
            <div className="pagination responsive-998">
              <div className="right-page">
                {Array.from(
                  {
                    length: Math.ceil(filteredUsers.length / usersPerPage),
                  },
                  (_, index) => {
                    const startPage = Math.max(currentPage - 2, 0);
                    const endPage = Math.min(
                      startPage + 3,
                      Math.ceil(filteredUsers.length / usersPerPage)
                    );

                    if (index >= startPage && index < endPage) {
                      return (
                        <button
                          key={index}
                          onClick={() => paginate(index + 1)} // Appeler la fonction paginate avec le numéro de page correspondant
                          className={
                            currentPage === index + 1
                              ? "pageNum active"
                              : "pageNum"
                          }
                        >
                          {index + 1}
                        </button>
                      );
                    } else if (index === startPage - 1 && currentPage > 3) {
                      return <span key="ellipsis-start">{"<<"}</span>;
                    } else if (
                      index === endPage &&
                      currentPage <
                        Math.ceil(filteredUsers.length / usersPerPage) - 2
                    ) {
                      return <span key="ellipsis-end">{">>"}</span>;
                    }
                    return null;
                  }
                )}
              </div>
            </div>
          </div>
          <div className="mt-30">{renderForm()}</div>
          <div className="bar mt-30"></div>
          <div className="mt-30 text-center">@ProjetAdidy</div>
        </div>
      </div>
    </>
  );
}

export default ReportingAdminPage;
